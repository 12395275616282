<template>
  <div>
    <b-row class="my-4">
      <b-col cols="12">
        <h3 class="center text-info float-left text-uppercase">
          {{ $t('sections.stages') }}
        </h3>
      </b-col>
    </b-row>

    <b-table
      show-empty
      responsive
      bordered
      striped
      hover
      :busy="loading"
      :items="list"
      :fields="fields"
      :emptyText="$t('messages.emptyTable')"
      :emptyFilteredText="$t('messages.emptyFilteredTable')"
    >
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2 align-items-center d-flex flex-column">
          <b-spinner class="align-middle flex-direction-column mb-3"></b-spinner>
          <strong>{{ $t('messages.loading') }}...</strong>
        </div>
      </template>

      <template v-slot:cell(isLive)="data">
        <p class="text-center m-0">
          <b-icon v-if="data.value" icon="check-circle-fill" variant="success" />
          <b-icon v-else icon="x-circle-fill" variant="danger" />
        </p>
      </template>

      <template v-slot:cell(actions)="data">
        <p class="text-center m-0">
          <b-button
            size="sm"
            variant="info"
            @click="$router.push({
              name: 'events-stages-edit',
              params: { id: data.item.id }
            })"
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-pencil-square"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5
                0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0
                0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5
                0 0 0 .196-.12l6.813-6.814z"
              />
              <path
                fill-rule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0
                0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0
                1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
            {{ $t('buttons.edit') }}
          </b-button>
        </p>
      </template>
    </b-table>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser';
import EventsStage from '../../../../services/EventsStage';

export default {
  data() {
    const data = {
      list: [],

      loading: true,

      fields: [
        { key: 'name', label: this.$t('inputs.name'), sortable: true },
        { key: 'isLive', label: this.$t('inputs.live'), sortable: true },
        { key: 'actions', label: this.$t('inputs.action') },
      ],
    };

    return data;
  },

  beforeMount() {
    this.onLoad();
  },

  methods: {
    async onLoad() {
      try {
        this.list = await EventsStage.get();
        this.totalRows = this.list.length;
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
